/* @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-schedule/styles/material.css'; */

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.contentContainer{
  height: 100%;
}

.clickable:hover{
  cursor : pointer;
}

.clickemphasis:hover {
  filter : brightness(0.8)
}

.fs-12 {
  font-size: calc(0.7rem + 0.1vw) !important;
}

.fs-14 {
  font-size: calc(0.8rem + 0.1vw) !important;
}

.fs-16 {
  font-size: calc(0.9rem + 0.1vw) !important;
}

.fs-18 {
  font-size: calc(1.1rem + 0.1vw) !important;
}

.fs-20 {
  font-size: calc(1.2rem + 0.1vw) !important;
}

.fs-22 {
  font-size: calc(1.3rem + 0.1vw) !important;
}

.fs-24 {
  font-size: calc(1.4rem + 0.1vw) !important;
}

.fs-26 {
  font-size: calc(1.5rem + 0.1vw) !important;
}

.fs-30 {
  font-size: calc(1.8rem + 0.1vw) !important;
}
.greenButton {
  background-color: #27bdbe;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: white;
  min-width: 112px;
}
.greenButton:hover{
  background-color: #27bdbe;
}
.greyButton {
  background-color: #7b7b7b;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: #ffffff;
  min-width: 112px;
}
.greyButton:hover{
  background-color: #7b7b7b;
}
.redButton {
  background-color: #f42929;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: #ffffff;
  min-width: 112px;
}
.redButton:hover{
  background-color: #d33737;
}
.blackButton {
  background-color: #000000;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: white;
  min-width: 112px;
}
.blackButton:hover{
  background-color: #000000;
}

@media screen and (max-width : 767px){
  .contentContainer{
    min-height: 0px;
  }
}