html, body {
  margin: 0;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hideOnDesktop {
  display: none;
}

@media screen and (max-width : 690px){
  .hideOnDesktop {
    display: block;
  }

  html {
    font-size: 10px;
  }
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap');

