.modalContainer{
    padding:8% 8% 8% 8%;
    background-color: #262a30 !important;
}

.myForm {
}

.loginform {
    font-weight: 500;
    line-height: 1.8;
    font-size: 20px;
}

.loginform input{
    font-size: 1.3rem;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #5d697a !important;
}

.MuiOutlinedInput-root .Mui-focused{
    border-color: #5d697a;
}

@media screen and (max-width : 690px){
    .myForm {
        width: "100%"
    }
}