.tickertable {
    font-size: 1.2rem !important;
}

.tickertable .MuiDataGrid-columnHeaders{
    background-color: rgb(52, 52, 52);
}

.watchlistdropdown {
    font-weight: bold !important;
}

.watchlistdropdownbtn .MuiSelect-select {
    padding: 6px 16px;
}

.watchlist-action {
    font-weight: bold !important;
}

.MuiDataGrid-withBorderColor {
    border-color: rgba(112, 128, 144,1) !important;
}
.MuiDataGrid-cellContent {
    margin: auto;
    color: #c6c7ca;
    font-weight: bold;
    line-height: 1;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
    margin: auto;
    color: white;
    font-weight: bold;
}

.MuiDataGrid-iconButtonContainer button {
    color: white !important
}

.MuiDataGrid-menuIcon button {
    color: white !important
}

.MuiTablePagination-selectLabel {
    margin: auto;
    color: white;
}

.MuiTablePagination-displayedRows {
    margin: auto;
    color: white;
}

.MuiTablePagination-actions button {
    margin: auto;
    color: white !important;
}
.MuiTablePagination-input {
    color: white !important;
}
.MuiSvgIcon-root {
    color: white !important;
}
.MuiDataGrid-selectedRowCount {
    color: white !important;
}
.MuiTablePagination-toolbar {
    font-size: 1.2rem !important;
}
.MuiTablePagination-toolbar .MuiTablePagination-selectLabel {
    font-size: 1.2rem !important;
}
.MuiTablePagination-toolbar .MuiTablePagination-displayedRows {
    font-size: 1.2rem !important;
}

.MuiMenu-list {
    background-color: rgb(238, 238, 238) !important;
}