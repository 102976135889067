#basic-navbar-nav .dropdown-menu{
    right: 0;
    left: unset;
}

.navbar-light .navbar-nav .nav-link {
    font-weight: bold;
    color: white;
}

.nav-text {
    font-weight: bold;
    color: white !important;
}

.topHeader{
    background-color: #ffbf80;
    padding : 20px;
    font-size: 1.3rem;
    color : #595959
}

.headertext {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
}

.login-text {
    font-size: 1.5rem;
}

.profiledropdown .nav-link:hover {
    color: white !important;
}

.logoText{
    font-size: 1.5rem
}
@media screen and (max-width : 690px){
    .logoText{
        font-size: 0.9rem
    }
    .navbar-toggler {
        padding:0.25rem 0.50rem;
    }
    .navbar-toggler-icon{
        width:0.8em;
        height:0.8em;
    }
}


@media screen and (max-width : 480px){
    .logoText{
        font-size: 0.8rem;
        text-align: left!important;
    }
    .topHeader{
        padding : 5px 15px;
    }
}

@media screen and (max-width : 400px){
    .logoText{
        text-align: left!important;
    }
    .navbar-brand{
        margin-right:0!important;
    }
}

@media screen and (max-width : 280px){
    .logoText{
        font-size:0.49rem;
    }
}
